@tailwind base;
@tailwind components;
@tailwind utilities;

.task-list-item::before {
  @apply hidden;
}

.task-list-item {
  @apply list-none;
}

.footnotes {
  @apply mt-12 border-t pt-8 border-gray-700;
}

.data-footnote-backref {
  @apply no-underline;
}

.csl-entry {
  @apply my-5;
}

/* https://stackoverflow.com/questions/61083813/how-to-avoid-internal-autofill-selected-style-to-be-applied */
input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 600000s 0s,
    color 600000s 0s;
}

.custom-substack-widget+.success,
.custom-substack-widget+.error {
  font-family: var(--font-inter), sans-serif !important;
  color: #cb3364 !important;
  font-size: 0.875rem;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #373c49;
}

::-webkit-scrollbar-thumb {
  background-color: #cb3364;
  border-radius: 2px;
}
